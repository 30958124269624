@import "../../data-store/variables.scss";
.contact{padding: 10em 0;
    h1{ margin-bottom: 1em; font-size: 4em; color: $white-color; font-weight: 700; text-transform: capitalize;}
    .contact_info{
        h2{color: $white-color;font-weight: 5   00;font-size: 1.7em;letter-spacing: 1px;margin: 1em 0;
            i{ padding-right: 1em; }
        }
    }

    .wrap{display: flex;align-items: stretch;justify-content: space-between;column-gap: 3em;background-color: #0e0d0dee; padding: 3em;
    iframe{ width: 50%; height: 600px; }
    form{width: 50%;
      label{color: $white-color;padding:0.5em 0 1em;font-size: 1em;font-weight: 600;}
        input{width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color; }
        textarea{ width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;}
        select{    width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;font-size: 1em;font-weight: 500;
            option{color: #000;font-weight: 500; letter-spacing: .5px; cursor: pointer;  }}
        button{width: 100%;color: $white-color;background-color: $parimary-color;border: none;font-size: 1.1em;font-weight: 600;text-transform: 
                uppercase;height: 3em;margin-top: 1.5em; } }}}
.error{
    color: red;
}

@media screen and (max-width:1199px) and (min-width:992px) {
    .contact{padding: 5em 0;
        h1{ margin-bottom: 1em; font-size: 3em; color: $white-color; font-weight: 700; text-transform: capitalize;}
        .contact_info{
            h2{color: $white-color;font-weight: 500;font-size: 1.3em;letter-spacing: 1px;margin: 1em 0;
                i{ padding-right: 1em; }
            }
        }
    
        .wrap{display: flex;align-items: stretch;justify-content: space-between;column-gap: 2em;background-color: #0e0d0dee; padding: 2em;
        iframe{ width: 50%; height: 600px; }
        form{width: 50%;
          label{color: $white-color;padding:0.5em 0 1em;font-size: 1em;font-weight: 600;}
            input{width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color; }
            textarea{  width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;}
            select{    width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;font-size: 1em;font-weight: 500;
                option{color: #000;font-weight: 500; letter-spacing: .5px; cursor: pointer;  }}
            button{width: 100%;color: $white-color;background-color: $parimary-color;border: none;font-size: 1.1em;font-weight: 600;text-transform: 
                    uppercase;height: 3em;margin-top: 1.5em; } }}}
}
@media screen and (max-width:991px) and (min-width:768px) {
    .contact{padding: 5em 0;
        h1{ margin-bottom: 1em; font-size: 2.7em; color: $white-color; font-weight: 700; text-transform: capitalize;}
        .contact_info{
            h2{color: $white-color;font-weight: 500;font-size: 1em;letter-spacing: 1px;margin: 1em 0;
                i{ padding-right: 1em; }
            }
        }
    
        .wrap{display: flex;align-items: stretch;justify-content: space-between;column-gap: 0;background-color: #0e0d0dee; row-gap: 2em; padding: 2em; flex-direction: column;
        iframe{ width: 100%; height: 300px; }
        form{width: 100%;
          label{color: $white-color;padding:0.5em 0 1em;font-size: 1em;font-weight: 600;}
            input{width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color; }
            textarea{  width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;}
            select{    width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;font-size: 1em;font-weight: 500;
                option{color: #000;font-weight: 500; letter-spacing: .5px; cursor: pointer;  }}
            button{width: 100%;color: $white-color;background-color: $parimary-color;border: none;font-size: 1.1em;font-weight: 600;text-transform: 
                    uppercase;height: 3em;margin-top: 1.5em; } }}}
}
@media screen and (max-width:767px) and (min-width:488px) {
    .contact{padding: 3em 0;
        h1{ margin-bottom: 1em; font-size: 2em; color: $white-color; font-weight: 700; text-transform: capitalize;}
        .contact_info{
            h2{color: $white-color;font-weight: 500;font-size: 0.8em;letter-spacing: 1px;margin: 1em 0;
                i{ padding-right: 1em; }
            }
        }
    
        .wrap{display: flex;align-items: stretch;justify-content: space-between;column-gap: 0;background-color: #0e0d0dee; row-gap: 2em; padding: 2em; flex-direction: column;
        iframe{ width: 100%; height: 300px; }
        form{width: 100%;
          label{color: $white-color;padding:0.5em 0 1em;font-size: 1em;font-weight: 600;}
            input{width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color; }
            textarea{  width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;}
            select{    width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;font-size: 1em;font-weight: 500;
                option{color: #000;font-weight: 500; letter-spacing: .5px; cursor: pointer;  }}
            button{width: 100%;color: $white-color;background-color: $parimary-color;border: none;font-size: 1.1em;font-weight: 600;text-transform: 
                    uppercase;height: 3em;margin-top: 1.5em; } }}}
}
@media screen and (max-width:487px) and (min-width:320px) {
    .contact{padding:2em 0;
        h1{ margin-bottom: 1em; font-size: 1em; color: $white-color; font-weight: 700; text-transform: capitalize;}
        .contact_info{
            h2{color: $white-color;font-weight: 500;font-size: 0.7em;letter-spacing: 0.3px;margin: 1em 0;
                i{ padding-right: 0.5em; }
            }
        }
    
        .wrap{display: flex;align-items: stretch;justify-content: space-between;column-gap: 0;background-color: #0e0d0dee; row-gap: 2em; padding: 2em; flex-direction: column;
        iframe{ width: 100%; height: 300px; }
        form{width: 100%;
          label{color: $white-color;padding:0.5em 0 1em;font-size: 0.7em;font-weight: 600;}
            input{width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;font-size: 0.7em; }
            textarea{  width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;font-size: 0.7em;color: $white-color; }
            select{    width: 100%;height: 3em;border-radius: 2px;border: 0.5px solid $white-color;background: transparent;color: $white-color;font-size: 0.7em;font-weight: 500;
                option{color: #000;font-weight: 500; letter-spacing: .5px; cursor: pointer;  }}
            button{width: 100%;color: $white-color;background-color: $parimary-color;border: none;font-size: 1.1em;font-weight: 600;text-transform: 
                    uppercase;height: 3em;margin-top: 1.5em; } }}}
}