@import "../../data-store/variables.scss";

.booking{
    background-color: #0e0d0dee; padding: 3em;
    h1{
        font-size: 2em;
        font-weight: 600;
        color: $white-color;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #c5c5c5;
        margin-bottom: 1em;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        
    }
    .wrap{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        column-gap: 3em;
       
    }
    .service_benifit{
        .top_info{
            
            p{
                font-size: 0.9em;
                font-weight: 400;
                margin-bottom: 1em;
                color: #ffffffd7;
            }
            h5{
                font-size: 1.3em;
                font-weight: 600;
                color: $white-color;
            }
        }
        img{
            width: 100%;
        }
        .info{
            .acroding{
                .accroding_item{
                    background-color: transparent;
                    .head{
                        width: 100%;
                        .button{
                            border:2px solid #000 ;
                            border-radius: 50px;
                            &::after{
                                display: none;
                            }
                        }
                    }
                    .show{
                        background-color: transparent;
                            .body{
                                color: #fff;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 25px;
                                position: relative;
                                padding: 15px 15px 15px 25px;
                                border-left: 1px dashed #8c8c8c;
                                margin-left: 20px;
                                margin-bottom: 15px;
                                margin-top: 15px;
                                text-align: justify;
                            ul{
                                padding: 0;
                                li{
                                    color: #fff;
                                    font-size: 1em;
                                }
        }
                            }       
                        }
                }
            }
           
        //     border: 0.5px solid #ffffff57;
        //     margin-top: 1em;
        
        // h2{
        //     font-size: 1.1em;
        //     font-weight: 600;
        //     color: $white-color;
        //     text-transform: uppercase;
        //     padding: 1em 0.5em;
        //     background-color: $parimary-color;
        // }

        
        ul{ padding: 1em;
            li{
                padding: 5px 0px 5px 25px;
                list-style: none;
                position: relative;
                margin-bottom: 3px;
                font-size: 0.7em;
                color: #fff;
                text-align: left;
                line-height: 1.2em;
                &::before{
                    content: "\f00c";
                    position: absolute;
                    font-family: 'FontAwesome';
                    color: #9c0000;
                    left: 2px;
                    font-size: 14px;
                
                }
            }
        }
    }
    }
    form{
        width: 50%;
        h2{
            font-size: 1.3em;
            font-weight: 700;
            letter-spacing: 0.5px;
            color: $parimary-color;
            text-transform: capitalize;
        }
      label{
        color: #fff;
        padding:0.5em 0 1em;
        font-size: 1em;
        font-weight: 600;
        display: none;
      }
      
        input{
            width: 100%;
            height: 3em;
            border-radius: 2px;
            border: 0.5px solid #fff;
            background: transparent;
            color: $white-color;
            margin: 1em 0;
            font-size: 1em;
            color: $white-color;
            text-transform: uppercase;
        }
        #email{text-transform: lowercase;}
        textarea{  
              width: 100%;
            height: 3em;
            border-radius: 2px;
            border: 0.5px solid #fff;
            background: transparent;}
            color: $white-color;
            font-size: 1em;
            text-transform: uppercase;
            color: $white-color;
        select{   
             width: 100%;
            height: 3em;
            border-radius: 2px;
            border: 0.5px solid #fff;
            background: transparent;
            color: #fff;
            font-size: 1em;
            font-weight: 500;
            font-size: 1em;
            text-transform: uppercase;
            margin: 1em 0;
            option{
                color: #000;
                font-weight: 600;
                letter-spacing: .5px;
                cursor: pointer;
                font-size: 1em;
                text-transform: uppercase;
            }
        }
        button{
            width: 100%;
            color: $white-color;
            background-color: $parimary-color;
            border: none;
            font-size: 1.1em;
            font-weight: 600;
            text-transform: uppercase;
          height: 3em;
          margin-top: 1.5em;
        }
        .tpy{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 1em;
            input{
                width: 100%;
            }
        }

    }
}

.error{
    color: red;
    font-size: 0.8em;
    text-transform: capitalize;
}