@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,700;0,800;0,900;1,400;1,600&display=swap');

html{
  scroll-behavior: smooth;
}

*{
  font-family: 'Montserrat', sans-serif;
  margin: 0;

}
body{
  margin: 0;
  padding: 0;
  background-color: #1f1f1f;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;

}
.menu-open{
  overflow: hidden;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding: 0;
}
ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}
.is-active{
  color: red;
}
.slick-slider{

  .slick-dots{
    bottom: 20px;
   
  }
  .slick-dots li button:before {
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    // opacity: .25;
    color: white;
}

}


@media screen and (max-width:467px) and (min-width: 320px){
  .slick-slider{
    .slick-dots{
      bottom: 20px;
      display: none;
      margin: 0;
    }
    .slick-dots li button:before {
      font-size: 15px;
      line-height: 15px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: '•';
      text-align: center;
      color: #fff;
      
  }
  
  }
}