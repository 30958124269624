

@import "../../data-store/variables.scss";
.short_info{padding: 5em 0; background-color: $secondery-color; border-top: 0.5px solid #ffffff23;
    .info_wrap{ display: flex; flex-direction: column-reverse; row-gap: 3em;
    .info{ 
      padding-bottom: 2em;
    h5{@include textCustom ( 1em, 600, 6px ,$white-color );text-transform: uppercase;padding-bottom: 0.5em;}
    h1{@include textCustom(3.5em, 700, .5px ,$white-color);text-transform: capitalize; padding-bottom: 0.7em}
    p{@include textCustom(1em, 400, .5px ,$white-color);line-height: 2em; padding-bottom: 2em; }
  
    }
  
      .imge{ position: relative;
      img{width: 100%;}
      .mobImg{display: none;}
      h2{display: flex; align-items: center; justify-content: center;
        @include textCustom(1.3em, 600, .5px ,$white-color);span{font-size: 4em;}
        position: absolute; bottom: 2vw;right: 2vw; padding: 1em; background-color: $parimary-color;}
    }
    }
  }

  @media screen  and (max-width:487px) and (min-width:320px){
    .short_info{padding: 5em 0; background-color: $secondery-color; border-top: 0.5px solid #ffffff23;
      .info_wrap{
      .info{ padding-bottom: 2em;
      h5{@include textCustom ( 0.7em, 600, 6px ,$white-color );text-transform: uppercase;padding-bottom: 0.5em;}
      h1{@include textCustom(2em, 700, .5px ,$white-color); padding-bottom: 0.7em}
      p{@include textCustom(0.7em, 400, .3px ,$paraghraph-color);line-height: 2em; }
    
      }
    
        .imge{ position: relative;
        img{width: 100%; }
        .desktopimg{
          display: none;
        }
      .mobImg{display: block;}

        h2{display: flex; align-items: center; justify-content: center; flex-direction: column;
          @include textCustom(1em, 600, .5px ,$white-color);span{font-size: 2em;}
          position: absolute; bottom: 2vw;left: 2vw; padding: 1em; background-color: $parimary-color;}
      }
      }
    }
  }