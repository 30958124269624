@import "../../data-store/variables.scss";
.service{
    margin: 5em 0;
    .nav_motors{
        
        text-align: center;
        .navbar{
            ul{
               display: flex;
               align-items: center;
               justify-content: space-evenly;
               background-color: $parimary-color;
               padding: 1.5em;
               li{
                background-color: #000;
                padding: 0.5em 2em;
                a{
                    color: #fff;
                    font-size: 1em;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
               }
            }
        }
    }
}
@media screen and (max-width:487px) and (min-width:320px) {
    .service{
        margin: 3em 0;
        .nav_motors{
            text-align: center;
            .navbar{
                ul{
                   display: flex;
                   align-items: center;
                   justify-content: space-evenly;
                   background-color: $parimary-color;
                   padding: 0.8em 1em;
                   li{
                    background-color: #000;
                    padding: 0.2em 0.5em ;
                    margin:0 0.2em;
                    a{
                        color: #fff;
                        font-size: 0.6em;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }
                   }
                }
            }
        }
    }
}