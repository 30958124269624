@import "../../data-store/variables.scss";

.banner{img{width: 100%;height: 100%; padding: 3em  15em;} }
.mobilebanner{display: none;}

.recentItem{padding: 5em 0;
  background-color: #000;
  .heading{display: flex; align-items: center; margin-bottom: 4em;
    h1{width: 60%; text-align: end; padding-right: 1em; @include textCustom(3em, 700, .50px ,$white-color);
      text-transform: uppercase; border-right: 2px solid $white-color;}
      h3{@include textCustom(1.2em,600,3px,$white-color);text-transform: uppercase;padding-left: 2em;}
  }
  .bike_wrap{ display: grid; grid-template-columns: repeat(3,1fr); align-items: stretch; justify-items: center; column-gap: 2em;
    .item{
    .imges{margin-bottom: 1em ; 
      position: relative;&::before{position: absolute; top: 0;bottom: 0;left: 0;right:0;
        background:linear-gradient(0deg, rgb(204 0 1 / 62%) 0%, rgb(204 0 1 / 11%) 66%, rgba(0, 0, 0, 0.4766281513) 93%);;content: "" ; width: 100%;height: 100%}
      img{width: 100%;  height:32vh}
    }
        
      h2{@include textCustom(2em,600,.5px, $white-color);span{text-transform: uppercase;} padding-bottom: 0.7em}
      p{@include textCustom (1em,400,.5px, $paraghraph-color);padding-bottom: 2em}
      a{@include textCustom(1em,600,.5px, $white-color);padding: 0.7em 1em ; background-color: $parimary-color; text-decoration: none;}
    }
  }
}

.short_info{padding: 5em 0; background-color: $secondery-color; 
  .info_wrap{display: flex;align-items: stretch;justify-content: space-between; column-gap: 2em;
    .info{ width: 100%; 
    h5{@include textCustom ( 1em, 600, 6px ,$white-color );text-transform: uppercase;padding-bottom: 0.5em;}
    h1{@include textCustom(3.5em, 700, .5px ,$white-color);text-transform: capitalize; padding-bottom: 0.5em}
    p{@include textCustom(1em, 400, .5px ,$white-color);line-height: 2em; padding-bottom: 4em }
    a{@include textCustom(1em,600,.5px, $white-color);padding: 0.7em 1em ; background-color: $parimary-color; text-decoration: none;}

  }

    .imge{ position: relative;
      img{width: 100%;}
      h2{display: flex; align-items: center; justify-content: center;
      @include textCustom(1.3em, 600, .5px ,$white-color);span{font-size: 4em;}
      position: absolute; bottom: 2vw;left: 2vw; padding: 1em; background-color: $parimary-color;}
  }
  }
}


.hero{ background-color: #000;padding: 1vw 0;
  .items_wrap{display: flex;align-items: center;justify-content: space-between; text-align: center;
  .item{
    h1{@include textCustom(3em,700,.5px,$white-color)}
    p{@include textCustom ( 1em, 600, 0.5px, $white-color)}
  }
  }
}



.delar{padding: 5em 0; background-color: $secondery-color;
  .info_wrap{ display: flex; column-gap: 2em;
  .info{ width: 80%;
  h1{@include textCustom(3em, 700, .5px ,$white-color); padding-bottom: 1em; }
  p{@include textCustom(1em, 00, .3px ,$paraghraph-color);line-height: 2em; }
  }
    .imge{ position: relative;
      img{width: 100%;}}
  }
}


// 1199px to 992px

@media screen and (max-width:1199px) and (min-width:992px) {
  .recentItem{padding: 5em 0;

    .heading{
      h1{@include textCustom(2em, 700, .50px ,$white-color);}
    }
    .bike_wrap{
      .item{
      .imges{margin-bottom: 1em ; 
        position: relative;&::before{position: absolute; top: 0;bottom: 0;left: 0;right:0;
          background:linear-gradient(0deg, rgb(204 0 1 / 62%) 0%, rgb(204 0 1 / 11%) 66%, rgba(0, 0, 0, 0.4766281513) 93%);;content: "" ; width: 100%;height: 100%}
        img{width: 100%;  height:32vh}
      }
          
        h2{@include textCustom(1.2em,600,.5px, $white-color); padding-bottom: 0.7em}
        p{@include textCustom (0.8em,300,.5px, $paraghraph-color)}
        a{@include textCustom(0.8em,600,.5px, $white-color);}
      }
    }
  }
  
  .short_info{padding:5em 0; background-color: $secondery-color; 
    .info_wrap{display: flex;align-items: stretch;justify-content: space-between; column-gap: 2em;
    .info{ width: 100%; 
    h5{@include textCustom ( 0.8em, 600, 6px ,$white-color );text-transform: uppercase;padding-bottom: 0.5em;}
    h1{@include textCustom(2em, 700, .5px ,$white-color); padding-bottom: 0.5em}
    p{@include textCustom(0.8em, 00, .3px ,$paraghraph-color);line-height: 2em; }
  
    }
  
      .imge{ position: relative;
      img{width: 100%;}
      h2{display: flex; align-items: center; justify-content: center;
        @include textCustom(1em, 600, .5px ,$white-color);span{font-size: 2.5em; padding-left: 0.5em;}
        }
    }
    }
  }
  
  
  .hero{
    background-color: #000;
    padding: 1vw 0;
    
    .items_wrap{display: flex;align-items: center;justify-content: space-between; text-align: center;
    .item{
      h1{@include textCustom(2em,700,.5px,$white-color)}
      p{@include textCustom ( 0.8em, 600, 0.5px, $paraghraph-color)}
    }
    }
  }
  
  
  
  .delar{padding: 5em 0; background-color: $secondery-color;
    .info_wrap{
    .info{
    h1{@include textCustom(2.5em, 700, .5px ,$white-color); padding-bottom: 1em;}
    p{@include textCustom ( 0.8em, 500, 0.5px, $paraghraph-color)}

    }
      .imge{ 
      img{width: 100%;}
    }
    }
  }
}

// 991px to 768px
@media screen and (max-width:991px) and (min-width:768px) {
  .recentItem{padding: 5em 0;
    background-color: #000;
    .heading{display: flex; align-items: center; margin-bottom: 3em;
      h1{width: 60%; text-align: end; padding-right: 1em; @include textCustom(1.7em, 700, .50px ,$white-color);
        text-transform: uppercase; border-right: 2px solid $white-color;}
        h3{@include textCustom(1em,600,3px,$white-color);text-transform: uppercase;padding-left: 2em;}
    }
    .bike_wrap{ display: grid; grid-template-columns: repeat(3,1fr); align-items: stretch; justify-items: center; column-gap: 2em;
      .item{
      .imges{margin-bottom: 1em ; 
        position: relative;&::before{position: absolute; top: 0;bottom: 0;left: 0;right:0;
          background:linear-gradient(0deg, rgb(204 0 1 / 62%) 0%, rgb(204 0 1 / 11%) 66%, rgba(0, 0, 0, 0.4766281513) 93%);;content: "" ; width: 100%;height: 100%}
        img{width: 100%;  height:27vh}
      }
          
        h2{@include textCustom(1.1em,600,.5px, $white-color);span{text-transform: uppercase;} padding-bottom: 0.7em}
        p{@include textCustom (0.7em,300,.5px, $paraghraph-color);padding-bottom: 2em}
        a{@include textCustom(0.7em,600,.5px, $white-color);padding: 0.7em 1em ; background-color: $parimary-color; text-decoration: none;}
      }
    }
  }
  
  .short_info{padding: 5em 0; background-color: $secondery-color; 
    .info_wrap{display: flex;align-items: stretch;justify-content: space-between; column-gap: 2em; flex-direction: column;
    .info{ width:100%;  padding-bottom: 2em;
    h5{@include textCustom ( 0.7em, 600, 6px ,$white-color );text-transform: uppercase;padding-bottom: 0.5em;}
    h1{@include textCustom(2em, 700, .5px ,$white-color); padding-bottom: 0.5em}
    p{@include textCustom(0.7em, 00, .3px ,$paraghraph-color);line-height: 2em; }
  
    }
  
      .imge{ position: relative;
      img{width: 100%;}
      h2{display: flex; align-items: center; justify-content: center;
        @include textCustom(1.1em, 600, .5px ,$white-color);span{font-size: 2em;}
        position: absolute; bottom: 6vw;left: 2vw; padding: 1em; background-color: $parimary-color;}
    }
    }
  }
  
  
  .hero{ background-color: #000; padding: 1vw 0; 
    .items_wrap{display: flex;align-items: center;justify-content: space-between; text-align: center;
    .item{
      h1{@include textCustom(2em,700,.5px,$white-color)}
      p{@include textCustom ( 0.7em, 600, 0.5px, $paraghraph-color)}
    }
    }
  }
  
  
  
  .delar{padding: 5em 0; background-color: $secondery-color;
    .info_wrap{
    .info{
    h1{@include textCustom(2em, 700, .5px ,$white-color); padding-bottom: 1em;}
    }
      .imge{ 
      img{width: 100%;}
    }
    }
  }
}
@media screen and (max-width:767px) and (min-width:498px) {
  .recentItem{padding: 3em 0;
    background-color: #000;
    .heading{display: flex; align-items: flex-start; margin-bottom: 1em; flex-direction: column; justify-content: flex-start;
      h1{width: 100%; text-align: start; padding-right: 0; padding-bottom: 1em; @include textCustom(1.3em, 700, .50px ,$white-color);
        text-transform: uppercase; border-right:none}
        h3{@include textCustom(0.8em,600,3px,$white-color);text-transform: uppercase;padding-left: 0;}
    }
    .bike_wrap{ display: grid; grid-template-columns: repeat(1,1fr); align-items: stretch; justify-items: center; column-gap: 0; row-gap: 2em;
      .item{
      .imges{margin-bottom: 1em ; 
        position: relative;&::before{position: absolute; top: 0;bottom: 0;left: 0;right:0;
          background:linear-gradient(0deg, rgb(204 0 1 / 62%) 0%, rgb(204 0 1 / 11%) 66%, rgba(0, 0, 0, 0.4766281513) 93%);;content: "" ; width: 100%;height: 100%}
        img{width: 100%;  height:100%}
      }
          
        h2{@include textCustom(1.1em,600,.5px, $white-color);span{text-transform: uppercase;} padding-bottom: 0.7em}
        p{@include textCustom (0.7em,300,.5px, $paraghraph-color);padding-bottom: 2em}
        a{@include textCustom(0.7em,600,.5px, $white-color);padding: 0.7em 1em ; background-color: $parimary-color; text-decoration: none;}
      }
    }
  }
  
  .short_info{padding: 5em 0; background-color: $secondery-color; 
    .info_wrap{display: flex;align-items: stretch;justify-content: space-between; column-gap: 2em; flex-direction: column;
    .info{ width:100%;  padding-bottom: 2em;
    h5{@include textCustom ( 0.7em, 600, 6px ,$white-color );text-transform: uppercase;padding-bottom: 0.5em;}
    h1{@include textCustom(2em, 700, .5px ,$white-color);; padding-bottom: 0.5em}
    p{@include textCustom(0.7em, 00, .3px ,$paraghraph-color);;line-height: 2em; }
  
    }
  
      .imge{ position: relative;
      img{width: 100%;}
      h2{display: flex; align-items: center; justify-content: center;
        @include textCustom(1.1em, 600, .5px ,$white-color);span{font-size: 2em;}
        position: absolute; bottom: 6vw;left: 2vw; padding: 1em; background-color: $parimary-color;}
    }
    }
  }
  
  
  .hero{ background-color: #000; padding: 1vw 0; 
    .items_wrap{display: flex;align-items: center;justify-content: space-between; text-align: center;
    .item{
      h1{@include textCustom(2em,700,.5px,$white-color)}
      p{@include textCustom ( 0.7em, 500, 0.5px, $paraghraph-color)}
    }
    }
  }
  
  
  
  .delar{padding: 5em 0; background-color: $secondery-color;
    .info_wrap{display: flex; flex-direction: column; row-gap: 2em;
    .info{ width: 100%;
    h1{@include textCustom(2em, 700, .5px ,$white-color); padding-bottom: 1em;}
    p{@include textCustom ( 0.7em, 500, 0.5px, $paraghraph-color)}

    }
      .imge{ 
      img{width: 100%;}
    }
    }
  }
}
@media screen and (max-width:497px) and (min-width:448px) {
  .recentItem{padding: 3em 0;
    background-color: #000;
    .heading{display: flex; align-items: flex-start; margin-bottom: 1em; flex-direction: column; justify-content: flex-start;
      h1{width: 100%; text-align: start; padding-right: 0; padding-bottom: 1em; @include textCustom(1.3em, 700, .50px ,$white-color);
        text-transform: uppercase; border-right:none}
        h3{@include textCustom(0.8em,600,3px,$white-color);text-transform: uppercase;padding-left: 0;}
    }
    .bike_wrap{ display: grid; grid-template-columns: repeat(1,1fr); align-items: stretch; justify-items: center; column-gap: 0; row-gap: 2em;
      .item{
      .imges{margin-bottom: 1em ; 
        position: relative;&::before{position: absolute; top: 0;bottom: 0;left: 0;right:0;
          background:linear-gradient(0deg, rgb(204 0 1 / 62%) 0%, rgb(204 0 1 / 11%) 66%, rgba(0, 0, 0, 0.4766281513) 93%);;content: "" ; width: 100%;height: 100%}
        img{width: 100%;  height:100%}
      }
          
        h2{@include textCustom(1.1em,600,.5px, $white-color);span{text-transform: uppercase;} padding-bottom: 0.7em}
        p{@include textCustom (0.7em,300,.5px, $paraghraph-color);padding-bottom: 2em}
        a{@include textCustom(0.7em,600,.5px, $white-color);padding: 0.7em 1em ; background-color: $parimary-color; text-decoration: none;}
      }
    }
  }
  
  .short_info{padding: 5em 0; background-color: $secondery-color; 
    .info_wrap{display: flex;align-items: stretch;justify-content: space-between; column-gap: 2em; flex-direction: column;
    .info{ width:100%;  padding-bottom: 2em;
    h5{@include textCustom ( 0.7em, 600, 6px ,$white-color );text-transform: uppercase;padding-bottom: 0.5em;}
    h1{@include textCustom(2em, 700, .5px ,$white-color);padding-bottom: 0.5em}
    p{@include textCustom(0.7em, 00, .3px ,$paraghraph-color);line-height: 2em; }
  
    }
  
      .imge{ position: relative;
      img{width: 100%;}
      h2{display: flex; align-items: center; justify-content: center;
        @include textCustom(1.1em, 600, .5px ,$white-color);span{font-size: 2em;}
        position: absolute; bottom: 6vw;left: 2vw; padding: 1em; background-color: $parimary-color;}
    }
    }
  }
  
  
  .hero{ background-color: #000; padding: 1vw 0; 
    .items_wrap{display: flex;align-items: center;justify-content: space-between; text-align: center;
    .item{
      h1{@include textCustom(2em,700,.5px,$white-color)}
      p{@include textCustom ( 0.7em, 600, 0.5px, $paraghraph-color)}
    }
    }
  }
  
  
  
  .delar{padding: 5em 0; background-color: $secondery-color;
    .info_wrap{
    .info{
    h1{@include textCustom(2em, 700, .5px ,$white-color);text-transform: uppercase; padding-bottom: 1em;}
    }
      .imge{ position: relative;&::before{position: absolute; top: 0;bottom: 0;left: 0;right:0;
        background:linear-gradient(0deg, rgb(204 0 1 / 62%) 0%, rgb(204 0 1 / 11%) 66%, rgba(0, 0, 0, 0.4766281513) 93%);;content: "" ; width: 100%;height: 100%}
      img{width: 100%;}
    }
    }
  }
}

@media screen and (max-width:447px) and (min-width:320px) {
  .banner{display: none;} .mobilebanner{display: block; img{width: 100%;}}
  // .banner{img{width: 100%;object-fit:fill; height: 40vh; transform: scale(1);}}
  .recentItem{padding: 2em 0;
    background-color: $secondery-color;
    .heading{display: flex; align-items: flex-start; margin-bottom: 1em; flex-direction: column; justify-content: flex-start;
      h1{width: 100%; text-align: start; padding-right: 0; padding-bottom: 1em; @include textCustom(1.2em, 700, .50px ,$white-color);
        text-transform: uppercase; border-right:none}
        h3{@include textCustom(0.7em,600,3px,$white-color);text-transform: uppercase;padding-left: 0;}
    }
    .bike_wrap{ display: grid; grid-template-columns: repeat(1,1fr); align-items: stretch; justify-items: center; column-gap: 0; row-gap: 2em;
      .item{
      .imges{margin-bottom: 1em ; 
        position: relative;&::before{position: absolute; top: 0;bottom: 0;left: 0;right:0;
          background:linear-gradient(0deg, rgb(204 0 1 / 62%) 0%, rgb(204 0 1 / 11%) 66%, rgba(0, 0, 0, 0.4766281513) 93%);;content: "" ; width: 100%;height: 100%}
        img{width: 100%;  height:100%}
      }
          
        h2{@include textCustom(1.1em,600,.5px, $white-color);span{text-transform: uppercase;} padding-bottom: 0.7em}
        p{@include textCustom (0.7em,500,.3px, $paraghraph-color);padding-bottom: 2em;}
        a{@include textCustom(0.7em,600,.5px, $white-color);padding: 0.7em 1em ; background-color: $parimary-color; text-decoration: none;}
      }
    }
  }
  
  .short_info{padding: 2em 0; background-color: #000;  position: relative;
    .info_wrap{display: flex;align-items: stretch;justify-content: space-between; column-gap: 2em; flex-direction: column;
    .info{ width:100%;  padding-bottom: 2em;
    h5{@include textCustom ( 0.6em, 600, 6px ,$white-color );text-transform: uppercase;padding-bottom: 0.5em;}
    h1{@include textCustom(1.5em, 700, .5px ,$white-color); padding-bottom: 0.5em}
    p{@include textCustom(0.7em, 500, .3px ,$paraghraph-color);line-height: 2em; }
  
    }
  
      .imge{ position: relative;&::before{position: absolute; top: 0;bottom: 0;left: 0;right:0;
        background:linear-gradient(0deg, rgb(204 0 1 / 62%) 0%, rgb(204 0 1 / 11%) 66%, rgba(0, 0, 0, 0.4766281513) 93%);;content: "" ; width: 100%;height: 100%}
      img{width: 100%;}
      h2{display: flex; align-items: center; justify-content: center; flex-direction: column;
        @include textCustom(1em, 600, .5px ,$white-color);span{font-size: 1.5em; font-weight: 700; padding-left: 0.2em;}
        position: absolute; top:2vw; bottom: 50vw;left: 2vw; padding: 1em; background:linear-gradient(90deg, rgba(204, 0, 0, 0.815) 0%, rgba(204, 0, 0, 0.63) 66%, rgba(197, 2, 2, 0) 93%);}
    }
    }
  }
  
  
  .hero{ background-color: transparent; padding: 1vw 0;  position: relative; top: -6em; height: 0; left: 0;
    right: 0;
    .items_wrap{display: flex;align-items: center;justify-content: space-around; text-align: center;
    .item{
      img{display: none;}
      h1{@include textCustom(1.5em,700,.5px,$white-color)}
      p{@include textCustom ( 0.7em, 600, 0.5px, $paraghraph-color)}
    }
    }
  }
  
  
  
  .delar{padding: 2em 0; background-color: $secondery-color;
    .info_wrap{ display: flex; column-gap: 2em; flex-direction: column;
      .info{ width: 100%;
      h1{@include textCustom(1.2em, 700, .5px ,$white-color); padding-bottom: 1em;  }
      p{@include textCustom(0.7em,500, .3px ,$paraghraph-color);line-height: 2em; padding-bottom: 2em; }
      }
        .imge{ position: relative;
          img{width: 100%;}}
      }
  }
}
