$parimary-color:#cc0001;
$secondery-color: #1f1f1f;
$third-color: #343232;
$red-light-color:#ffe5e5;
$white-color:#fff;
$paraghraph-color:#e2e2e2;



// Mixin


@mixin textCustom($fnt,$fntw,$lspa,$color){
    font-size: $fnt;
    font-weight: $fntw;
    letter-spacing: $lspa;
    color: $color;
}


//form{
    //     width: 50%;
    //   label{
    //     color: #fff;
    //     padding:0.5em 0 1em;
    //     font-size: 1em;
    //     font-weight: 600;
    //     display: none;
    //   }
      
    //     input{
    //         width: 100%;
    //         height: 3em;
    //         border-radius: 2px;
    //         border: 0.5px solid #fff;
    //         background: transparent;
    //         color: $white-color;
    //         margin: 1em 0;
    //         font-size: 1em;
    //         color: $white-color;
    //         text-transform: uppercase;
    //     }
    //     #email{text-transform: lowercase;}
    //     textarea{  
    //           width: 100%;
    //         height: 3em;
    //         border-radius: 2px;
    //         border: 0.5px solid #fff;
    //         background: transparent;}
    //         color: $white-color;
    //         font-size: 1em;
    //         text-transform: uppercase;
    //         color: $white-color;
    //     select{   
    //          width: 100%;
    //         height: 3em;
    //         border-radius: 2px;
    //         border: 0.5px solid #fff;
    //         background: transparent;
    //         color: #fff;
    //         font-size: 1em;
    //         font-weight: 500;
    //         font-size: 1em;
    //         text-transform: uppercase;
    //         option{
    //             color: #000;
    //             font-weight: 600;
    //             letter-spacing: .5px;
    //             cursor: pointer;
    //             font-size: 1em;
    //             text-transform: uppercase;
    //         }
    //     }
    //     button{
    //         width: 100%;
    //         color: $white-color;
    //         background-color: $parimary-color;
    //         border: none;
    //         font-size: 1.1em;
    //         font-weight: 600;
    //         text-transform: uppercase;
    //       height: 3em;
    //       margin-top: 1.5em;
    //     }
    //     .tpy{
    //         display: grid;
    //         grid-template-columns: repeat(2,1fr);
    //         column-gap: 1em;
    //         input{
    //             width: 100%;
    //         }
    //     }
    
    // }