@import "../../data-store/variables.scss";

header{
    .top_nav{ background-color: #000; text-align: center; padding: 1em;
    h1{@include textCustom(1em,600, .5px, $white-color);margin: 0; i{color: green; font-size: 1em; font-weight: 900;}}
    }
    // navbar Animation
    .navbar.colorChange { position: fixed; width: 100%; animation: animed 0.6s ease-in-out;  top: 0;  background-color: $third-color; 
       z-index: 4;  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.39);}
      @keyframes animed {0% {  transform: translateY(-100%);}100% {  transform: translateY(0%); } }
// main nav
    .navbar{background-color: $third-color; padding: 1em; transition: 0.6s ease-in-out; 
        .nav_wrap{display: flex;align-items: stretch;justify-content: space-between; 
        .logo{width: 10%; img{width: 100%;}}
        .menu{display: flex; flex-direction: column; justify-content: space-between; align-items: flex-end;
          h1{@include textCustom(2em,700,.0.5px,$parimary-color)}
          ul { margin: 0; padding: 0;
            li{display: inline;padding: 0 0.7em; 
              &:hover a::before{position:absolute ;  right: 0; left: 0; bottom: -4px; background-color: $parimary-color; width: auto; height: 3px; content: ""; 
                animation: slid 0.6s ease-in-out;}
              @keyframes slid {
                to{
                  left: 0%;
                }
                from{
                  right: 100%;
                }
              }
              &:hover a{ color: $parimary-color}
                a{@include textCustom(1.1em,700,.5px,$white-color);text-decoration: none;transition: 0.6s ease-in-out; position: relative; .active{color: red;}}
                
            }}}} }
}
.mobile{.top_nav{display: none;}}
.mobile_nav { display: none; }
.toggle_menu {display: none;}

// Responsive start

@media screen and (max-width:991px) and (min-width:768px) {
  header{display: none;}
  .mobile{ display: block;
  .top_nav{display: block; background-color: #000; text-align: center; padding: 1em; h1{@include textCustom(1em,600, .5px, $white-color);margin: 0;}}
    // animation navbar
    .mobile_nav.colorChange { position: fixed; width: 100%; top: 0; background-color: $secondery-color; z-index: 6; animation: animed 0.6s ease-in-out;}
    @keyframes animed {0% { transform: translateY(-100%);} 100% {  transform: translateY(0%);}}
    // mobile nav start
    .mobile_nav {display: block;width: 100%; position: relative; top: 0; background-color: $secondery-color; border-bottom: 1px solid #000; z-index: 6;padding: 1rem 0;
     .nav_bar {display: flex;flex-direction: row;align-items: center;justify-content: space-between;
      h1{@include textCustom(2em,600,0.5px, $parimary-color)}
       .logo {width: 20%; img {  width: 100%;}}
       .toggle_button { display: flex ; flex-direction: column; align-items: flex-end ;
         button {position: relative; width: 50px; height: 50px; display: flex; justify-content: center; align-items: center; overflow: hidden; border: none; 
                  background-color: transparent; display: block; z-index: 4;
            &.menuicons span { transform: translateX(60px);}
             &.menuicons span:nth-child(1) { transform: translateY(0px); width: 20px; transform: rotate(50deg); }
              &.menuicons span:nth-child(2) {  transform: translateY(0px);  width: 20px;  transform: rotate(128deg);}
              & span { position: absolute; width: 30px; height: 3px; background-color: $parimary-color; border-radius: 4px; transition: 0.5s; right: 15px;
               &:nth-child(1) { transform: translateY(-10px); width: 20px; left: 15px; }
               &:nth-child(2) { transform: translateY(10px); width: 20px; left: 15px; }}
              }}}}}
       // Toggel menu start
       .toggle_menu { display: block; position: fixed; top: 0; right: 0; left: 0; bottom: 0; content: "";  z-index: 5;   background-color: $red-light-color;  margin: 0;
                      transform: translate(110%); padding-top: 6rem; overflow: hidden;  overflow-y: scroll;  height: auto;
            .menus {padding: 3rem 0;
              ul { padding: 2rem 0;
                 li { display: block; text-align: left; margin-bottom:2em; padding: 0rem; border-bottom: 1px solid rgb(146, 145, 145);
                   a { font-size: 1.2rem; font-weight: 600; letter-spacing: .5px;  line-height: 2rem; 
                    text-decoration: none; color: $third-color;
                    &.active {  color: $third-color;} }} }}}
      // Active menu
      .actives { transform: translate(0%); transition: 0.6s; }

}

@media screen and (max-width:767px) and (min-width:498px) {
  header{display: none;}
  .mobile{
    .top_nav{display: block; background-color: #000; text-align: center; padding: 1em; h1{@include textCustom(1em,600, .5px, $white-color);margin: 0;}}
      // animation navbar
      .mobile_nav.colorChange { position: fixed; width: 100%; top: 0; background-color: $secondery-color; z-index: 6; animation: animed 0.6s ease-in-out;}
      @keyframes animed {0% { transform: translateY(-100%);} 100% {  transform: translateY(0%);}}
      // mobile nav start
      .mobile_nav {display: block;width: 100%; position: relative; top: 0; background-color: $secondery-color; border-bottom: 1px solid #000; z-index: 6;padding: 1rem 0;
        .nav_bar {display: flex;flex-direction: row;align-items: center;justify-content: space-between;
          h1{@include textCustom(2em,600,0.5px, $parimary-color)}
          .logo {width: 20%; img {  width: 100%;}}
          .toggle_button { display: flex ; flex-direction: column; align-items: flex-end ;
            button {position: relative; width: 50px; height: 50px; display: flex; justify-content: center; align-items: center; overflow: hidden; border: none; 
                     background-color: transparent; display: block; z-index: 4;
               &.menuicons span { transform: translateX(60px);}
                &.menuicons span:nth-child(1) { transform: translateY(0px); width: 20px; transform: rotate(50deg); }
                 &.menuicons span:nth-child(2) {  transform: translateY(0px);  width: 20px;  transform: rotate(128deg);}
                 & span { position: absolute; width: 30px; height: 3px; background-color: $parimary-color; border-radius: 4px; transition: 0.5s; right: 15px;
                  &:nth-child(1) { transform: translateY(-10px); width: 20px; left: 15px; }
                  &:nth-child(2) { transform: translateY(10px); width: 20px; left: 15px; }}
                 }}}}}
         // Toggel menu start
             .toggle_menu { display: block; position: fixed; top: 0; right: 0; left: 0; bottom: 0; content: "";  z-index: 5;   background-color: $red-light-color;  margin: 0;
                      transform: translate(110%); padding-top: 6rem; overflow: hidden;  overflow-y: scroll;  height: auto;
            .menus {padding: 3rem 0;
              ul { padding: 2rem 0;
                 li { display: block; text-align: left; margin-bottom:2em; padding: 0rem; border-bottom: 1px solid rgb(146, 145, 145);
                   a { font-size: 1.2rem; font-weight: 600; letter-spacing: .5px;  line-height: 2rem; 
                    text-decoration: none; color: $third-color;
                    &.active {  color: $third-color;} }} }}}
        // Active menu
        .actives { transform: translate(0%); transition: 0.6s; }
}
@media screen and (max-width:497px) and (min-width:320px) {
  header{display: none;}
  .mobile{
    .top_nav{display: block; background-color: #000; text-align: center; padding: 0.2em 5.5em;  h1{@include textCustom(0.8em,600, .5px, $white-color);margin: 0; line-height: 2em;
    i{color: green; font-size: 1em;}
    }}
      // animation navbar
      .mobile_nav.colorChange { position: fixed; width: 100%; top: 0; background-color: $secondery-color; z-index: 6; animation: animed 0.6s ease-in-out;}
      @keyframes animed {0% { transform: translateY(-100%);} 100% {  transform: translateY(0%);}}
      // mobile nav start
      .mobile_nav {display: block;width: 100%; position: relative; top: 0; background-color: $secondery-color; border-bottom: 1px solid #000; z-index: 6;padding: 1rem 0;
        .nav_bar {display: flex;flex-direction: row;align-items: center;justify-content: space-between;
          h1{@include textCustom(1.4em,800,0.5px, $parimary-color)}
          .logo {width: 20%; img {  width: 100%;}}
          .toggle_button { display: flex ; flex-direction: column; align-items: flex-end ;
            button {position: relative; width: 50px; height: 50px; display: flex; justify-content: center; align-items: center; overflow: hidden; border: none; 
                     background-color: transparent; display: block; z-index: 4;
               &.menuicons span { transform: translateX(60px);}
                &.menuicons span:nth-child(1) { transform: translateY(0px); width: 20px; transform: rotate(50deg); }
                 &.menuicons span:nth-child(2) {  transform: translateY(0px);  width: 20px;  transform: rotate(128deg);}
                 & span { position: absolute; width: 30px; height: 3px; background-color: $parimary-color; border-radius: 4px; transition: 0.5s; right: 15px;
                  &:nth-child(1) { transform: translateY(-10px); width: 20px; left: 15px; }
                  &:nth-child(2) { transform: translateY(10px); width: 20px; left: 15px; }}
                 }}}}}
         // Toggel menu start
           .toggle_menu { display: block; position: fixed; top: 0; right: 0; left: 0; bottom: 0; content: "";  z-index: 5;   background-color: $red-light-color;  margin: 0;
                      transform: translate(110%); padding-top: 6rem; overflow: hidden;  overflow-y: scroll;  height: auto;
            .menus {padding: 1.2rem 0;
              ul { padding: 1rem 0;
                 li { display: block; text-align: left; margin-bottom:2em; padding: 0rem; border-bottom: 1px solid rgb(146, 145, 145);
                   a { font-size: 0.8rem; font-weight: 600; letter-spacing: .5px;  line-height: 2rem; 
                    text-decoration: none; color: $third-color;
                    &.active {  color: $third-color;} }} }}}
        // Active menu
        .actives { transform: translate(0%); transition: 0.6s; }

}