@import "../../data-store/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap');
.footer{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.733) 0%, rgba(0, 0, 0, 0.733) 66%, rgba(0, 0, 0, 0.733) 93%), url("../../../public/assets/bg.webp");
    // background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 5em 0;
  .footer_wrap{ display: flex; align-items: stretch; justify-content: space-between; padding-bottom: 2em;
    .item{
        h1{@include textCustom (2em,700,.5px,$white-color);text-transform: uppercase;padding-bottom: 1em;}
        ul{ li{ color: $white-color; font-weight: 500;line-height: 2em; font-size: 1.2em;
            i{ padding-right: 0.5em;}       
            a{color: $white-color;text-transform: capitalize; }}
            .location{ position: relative;padding-left: 1.7em;
              &::before{content: "\f3c5";position: absolute;font-family: 'FontAwesome';left: 2px;font-size: 1.2em;}
            }
        }
    }
  }
  .footer_bottom{display: flex; align-items: center; justify-content: space-between; padding-top: 1em; border-top: 0.5px solid #ffffff69;
    p{color: $paraghraph-color;font-size: 1em;
    a{font-family: 'Libre Barcode 39 Text', cursive;  color: #fad4d4;  font-size: 1.2em; }
    }
  }
}


@media screen and (max-width:767px) and (min-width: 448px) {
    .footer{
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.904) 0%, rgba(0,0,0,0.904) 66%, rgba(0,0,0,0.904) 93%), url("../../../public/assets/bg.webp");
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 2em 0;
      .footer_wrap{ display: flex; align-items: stretch; justify-content: space-between; padding-bottom: 2em; flex-direction: column;
        .item{
            h1{@include textCustom (1em,700,.5px,$white-color);text-transform: uppercase;padding-bottom: 1em; padding-top: 1em;}
            ul{ li{ color: $white-color; font-weight: 500;line-height: 2em; font-size: 0.7em;
                i{ padding-right: 0.5em;}       
                a{color: $white-color;text-transform: capitalize; }}
            }
        }
      }
      .footer_bottom{display: flex; align-items: center; justify-content: space-between; padding-top: 1em; border-top: 0.5px solid #ffffff69;
        p{font-size: 0.7em;
        a{font-family: 'Libre Barcode 39 Text', cursive;  color: #fad4d4;  font-size: 1.2em; }
        }
      }
    }
}

@media screen and (max-width:447px) and (min-width: 320px) {
    .footer{
      padding: 2em 0;
      .footer_wrap{ display: flex; align-items: stretch; justify-content: space-between; padding-bottom: 2em; flex-direction: column;
        .item{
            h1{@include textCustom (1em,700,.5px,$white-color);text-transform: uppercase;padding-bottom: 1em; padding-top: 1em;}
            ul{ li{ color: $white-color; font-weight: 500;line-height: 2em; font-size: 0.7em;
                i{ padding-right: 0.5em;}       
                a{color: $white-color;text-transform: capitalize; }}
            }
        }
      }
      .footer_bottom{display: flex; align-items: center; justify-content: space-between; padding-top: 1em; border-top: 0.5px solid #ffffff69;
        p{font-size: 0.7em;
        a{font-family: 'Libre Barcode 39 Text', cursive;  color: #fad4d4;  font-size: 1.2em; }
        }
      }
    }
}

// @media screen and (max-width:767px) and (min-width: 448px) {
//     .footer{
//         background: linear-gradient(90deg, rgba(0, 0, 0, 0.904) 0%, rgba(0,0,0,0.904) 66%, rgba(0,0,0,0.904) 93%), url("../../../public/assets/bg.webp");
//         background-attachment: fixed;
//         background-repeat: no-repeat;
//         background-size: cover;
//         padding: 2em 0;
//       .footer_wrap{ display: flex; align-items: stretch; justify-content: space-between; padding-bottom: 2em; flex-direction: column;
//         .item{
//             h1{@include textCustom (1em,700,.5px,$white-color);text-transform: uppercase;padding-bottom: 1em; padding-top: 1em;}
//             ul{ li{ color: $white-color; font-weight: 500;line-height: 2em; font-size: 0.7em;
//                 i{ padding-right: 0.5em;}       
//                 a{color: $white-color;text-transform: capitalize; }}
//             }
//         }
//       }
//       .footer_bottom{display: flex; align-items: center; justify-content: space-between; padding-top: 1em; border-top: 0.5px solid #ffffff69;
//         p{color: $white-color;font-size: 0.7em;
//         a{font-family: 'Libre Barcode 39 Text', cursive;  color: #fad4d4;  font-size: 1.2em; }
//         }
//       }
//     }
// }