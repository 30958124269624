@import "../../../data-store/variables.scss";

.booking{background-color: #0e0d0dee; padding: 3em;
    h1{ font-size: 2em; font-weight: 600; color: $white-color;  padding-bottom: 0.5rem;  border-bottom: 1px solid #c5c5c5; 
         margin-bottom: 1em; text-transform: uppercase; letter-spacing: 0.5px;}
    .wrap{ display: flex; align-items: stretch; justify-content: space-between; column-gap: 3em;  }
    .service_benifit{
        img{width: 100%;}
        .info{border: 0.5px solid #ffffff57;margin-top: 1em;  }
        h2{ font-size: 1.1em; font-weight: 600; color: $white-color;  text-transform: uppercase; padding: 1em 0.5em; background-color: $parimary-color; }

        ul{ padding: 1em;
            li{ padding: 5px 0px 5px 25px; list-style: none; position: relative; margin-bottom: 3px; font-size: 0.7em; color: #fff;  text-align: left;  line-height: 1.2em;
                &::before{content: "\f00c";position: absolute;font-family: 'FontAwesome';color: #9c0000;left: 2px;font-size: 14px;}
            }
        }
    }

}

.error{
    color: red;
    font-size: 0.8em;
    text-transform: capitalize;
}

@media screen and ( max-width:487px) and (min-width:320px){
    .booking{background-color: #0e0d0dee; padding: 1.5em;
        h1{ font-size: 1.2em; font-weight: 600; color: $white-color;  padding-bottom: 0.5rem;  border-bottom: 1px solid #c5c5c5; 
             margin-bottom: 1em; text-transform: uppercase; letter-spacing: 0.5px;}
        .wrap{ display: flex; align-items: stretch; justify-content: space-between; column-gap: 0em;  }
        .service_benifit{
            img{width: 100%;}
            .info{border: 0.5px solid #ffffff57;margin-top: 1em;  }
            h2{ font-size: 0.8em; font-weight: 600; color: $white-color;  text-transform: uppercase; padding: 1em 0.2   em; background-color: $parimary-color; }
    
            ul{ padding: 1em;
                li{ padding: 5px 0px 5px 25px; list-style: none; position: relative; margin-bottom: 3px; font-size: 0.7em; color: #fff;  text-align: left;  line-height: 1.2em;
                    &::before{content: "\f00c";position: absolute;font-family: 'FontAwesome';color: #9c0000;left: 2px;font-size: 14px;}
                }
            }
        }
    
    }

}